.user-profile-page {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user-avatar {
    max-width: 50px;
    height: auto;
    object-fit: cover;
    margin-right: 60px;
}

.user-change-profile-img {
    background-color: rgb(68, 68, 68);
    color: white;
    border-radius: 5px;
    width: 200px;
    height: 30px;
    margin-top: 30px;
}

.user-profile-img-change-field {
    margin-top: 30px;
    width: 400px;
}

.submit-profile-button {
    background-color: rgb(68, 68, 68);
    color: white;
    border-radius: 5px;
    width: 200px;
    height: 30px;
    margin-top: 10px;
}

.user-page-profile-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.user-games-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 60vw;
    margin-left: 60px;
    margin-right: 60px;
}

.user-elim-link {
    text-decoration: none;
    color: rgb(120, 28, 156);
    font-size: 22px;
    font-weight: 600;
}

.user-elim-record {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    margin-right: 60px;
}

.eliminator-pick {
    font-size: 14px;
}

.user-pickem-record {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    margin-right: 60px;
}

.user-page-ph {
    margin-top: 60px;
}

.no-profile-no-user-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user-ou-bet-game {
    margin-top: 10px;
}

/* .spread-record {
    margin-left: 60px;
} */

/* .user-spread-bets {
    margin-left: 20px;
} */

.user-over-under-bets {
    margin-right: 60px;
}

.user-record-subtitle {
    margin-top: 40px;
}

.coin-img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}
