
.site-footer {
    background-color: #181818;
    color: #fff;
    padding: 10px;
    margin-top: 40px;
    /* padding: 20px 0; */
    padding-top: 10px;
    padding-bottom: 10px;
    position: sticky;
    bottom: 0;
    max-height: 50px;
}

.footer-container {
    width: 96.8vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footer-created-by {
    font-size: 10px;
    margin-right: 10px;
}

.social {
    display: flex;
    justify-content: space-between;
    width: 400px;
    align-items: center;
}

.social a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
    font-size: 16px;
}

.footer-github-logo {
    height: 30px;
    object-fit: cover;
}

.footer-linkedin-logo {
    height: 30px;
}

.footer-email-logo {
    height: 40px;
}

.footer-creator-name {
    font-size: 26px;
    /* font-weight: bold; */
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: rgb(120, 28, 156);
}

.favicon-attribution {
    font-size: 12px;
    color:rgb(20, 125, 156)
}
