.splash-overall-div {
    width: '50vw';
    height: '100vh';
    background: '#f0f0f0';
    display: flex;
    flex-direction: column;
    align-items: center;
}

.splash-title {
    color: #4b0082;
    background-image: linear-gradient(to right, #5c2583, #089ba0); /* Gradient from purple to blue */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    transition: background-position 0.3s;
    text-decoration: none;
    font-size: 30px;
}

.splash-login-button {
    margin-right: 20px;
}

.splash-gamelist-ul {
    list-style: none;
}

.splash-gamelist-ul {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding: 0;
    list-style: none;
    margin-top: 20px;
  }

  .splash-gamelist-li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid #089ba0;
    padding: 10px;
  }

  .splash-game-link {
    text-decoration: none;
    color: rgb(120, 28, 156);
    font-size: 18px;
    font-weight: 600;
  }

  .splash-gamelist-title {
    width: 25%;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
  }

  .splash-gamelist-description {
    width: 55%;
    margin-left: 10px;
    font-size: 12px;
    max-width: 500px;
  }

  .splash-gamelist-status {
    width: 20%;
    text-align: right;
    margin-right: 10px;
  }

.main-commentform-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-commentlist-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
}

.user-ou-bet-game {
  font-size: 14px;
}
