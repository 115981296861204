.guest-comment-title {
  text-align: center;
}

.all-comments-div {
  width: 60vw;
}

.each-comment-div {
  border: 2px solid #4a2e69;
  display: flex;
  border-radius: 10px;
}

.comment-img-username {
  flex: 0 0 20%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.comment-username-div {
  margin-bottom: 16px;
  margin-top: 6px;
}

.comment-profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.comment-text-div {
  width: 45vw;
  margin: 20px 20px 20px 0px;
  flex: 1;
  padding: 20px 20px 20px 0px;
  box-sizing: border-box;
  overflow-wrap: break-word;
  font-size: 14px;
}

.comment-delete-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: rgb(148, 148, 148);
  font-size: 12px;
  margin-left: 20px;
  margin-bottom: 10px;
}

.comment-edit-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: rgb(148, 148, 148);
  font-size: 12px;
}

.comment-edit-field {
  width: 45vw;
  height: 50%;
  background-color: rgb(68, 68, 68);
  color: white;
  width: 45vw;
  border-radius: 10px;
  padding: 10px;
  margin-top: 4px;
}

.comment-edit-confirm-button {
  height: 20px;
  width: 120px;
  background-color: rgb(68, 68, 68);
  color: white;
  border-radius: 5px;
  margin-top: 4px;
}

.comment-date-time-div {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.comment-date-time-span {
  color: rgb(148, 148, 148);
  font-size: 12px;
}
