/* TODO Add site wide styles */

body {
    background-color: rgb(34, 34, 34);
    color: rgb(207, 206, 206);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin: 0;
    padding: 0;
}

body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-thumb {
    background: #000;
}

body::-webkit-scrollbar-track {
    background: #333;
}

body {
    scrollbar-color: #000 #333;
}


body {
    -ms-overflow-style: none;
    scrollbar-color: #000 #333;
}
