.profile-dropdown {
  position: absolute;
  list-style: none;
  padding: 10px;
  border-radius: 10px;
  border: 3px solid #4b0082;
  background-color: #181818;
  margin: 10px 10px 10px 10px;
  left: 80vw;
  padding: 10px 10px 10px 10px;
  max-width: 190px;
  min-width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navbar-div {
  width: 100vh;
  background-color:  #181818;
}

.hidden {
  display: none;
}

.nav-ul {
  margin: 0px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  background-color: #181818;
  padding: 20px;
  max-width: 100vw;
  align-items: center;
  /* width: 96.8vw; */
}

.p-or-p-title {
    font-size: 2rem;
    color: #5c2583;
    background-image: linear-gradient(to right, #5c2583, #089ba0); /* Gradient from purple to blue */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    transition: background-position 0.3s;
    text-decoration: none;
    font-weight: 700;
}

.p-or-p-title:hover {
    background-position: 100%;
}

.profile-dropdown-button {
  margin-right: 100px;
  height: 30px;
  width: 60px;
  background-color: rgb(68, 68, 68);
  color: white;
  border-radius: 5px;
  margin-top: 4px;
}

#profile-icon-id {
  /* height: 20px; */
}

.dropdown-item {
  padding: 12px;
}

/* #dropdown-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

.profile-logout-button {
  height: 30px;
  width: 70px;
  background-color: rgb(68, 68, 68);
  color: white;
  border-radius: 5px;
  margin-top: 4px;
}

.nav-user-profile {
  text-decoration: none;
  color:  #089ba0;
}
