#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}



#modal-content {
  position: absolute;
  border: 3px solid rgb(107, 107, 107);
  border-radius: 10px;
  padding: 0px 20px 20px 20px;
}
