.comment-field {
    background-color: rgb(68, 68, 68);
    color: white;
    width: 55vw;
    height: 80px;
    border-radius: 10px;
}

#comment {
    padding: 10px;
}

.leave-comment-title-h3 {
    text-align: center;
}

.submit-comment-span {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 4px;
}

.submit-comment-button {
    background-color: rgb(68, 68, 68);
    color: white;
    border-radius: 5px;
    width: 200px;
    height: 30px;
}

.comment-explanation-span {
    font-size: 8px;
    margin-left: 5px;
    margin-right: 30vw;
}
